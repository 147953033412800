@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  #root {
    /* Full height of screen and flex so that we can push footer */
    /* to the bottom even if content is shorter than screen */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  /* Prevent child elements from shrinking when content */
  /* is taller than the screen (quirk of having flex parent) */
  #root > * {
    flex-shrink: 0;
  }
}

@layer components {
  .fixed-top {
      position: fixed !important;
      top: 0 !important;
      width: 100% !important;
      z-index: 1000 !important;
  }
  .sticky-top {
    position: sticky !important;
    top: 0 !important;
    z-index: 1000 !important; /* Ensure it stays on top of other content */
  }
}

@layer utilities {
  .section.bg-white + .section.bg-white {
    border-top: 1px solid #f0f0f0;
  }
  .pattern-dots {
    background-image: radial-gradient(currentColor 2px, transparent 2px);
    background-size: calc(10 * 2px) calc(10 * 2px);
  }
  .logo {
    width: 100%;
    max-width: 140px; /* Adjust max-width as needed */
    height: auto;
  }

  @media (max-width: 500px) {
    .logo {
      max-width: 100px; /* Smaller logo for smaller screens */
    }
  }
}
